import { z } from "zod";

export const AlliancesModel = z.object({
  16000000: z.literal("Flame_01"),
  16000001: z.literal("Flame_02"),
  16000002: z.literal("Flame_03"),
  16000003: z.literal("Flame_04"),
  16000004: z.literal("Sword_01"),
  16000005: z.literal("Sword_02"),
  16000006: z.literal("Sword_03"),
  16000007: z.literal("Sword_04"),
  16000008: z.literal("Bolt_01"),
  16000009: z.literal("Bolt_02"),
  16000010: z.literal("Bolt_03"),
  16000011: z.literal("Bolt_04"),
  16000012: z.literal("Crown_01"),
  16000013: z.literal("Crown_02"),
  16000014: z.literal("Crown_03"),
  16000015: z.literal("Crown_04"),
  16000016: z.literal("Arrow_01"),
  16000017: z.literal("Arrow_02"),
  16000018: z.literal("Arrow_03"),
  16000019: z.literal("Arrow_04"),
  16000020: z.literal("Diamond_Star_01"),
  16000021: z.literal("Diamond_Star_02"),
  16000022: z.literal("Diamond_Star_03"),
  16000023: z.literal("Diamond_Star_04"),
  16000024: z.literal("Skull_01"),
  16000025: z.literal("Skull_02"),
  16000026: z.literal("Skull_03"),
  16000027: z.literal("Skull_04"),
  16000028: z.literal("Skull_05"),
  16000029: z.literal("Skull_06"),
  16000030: z.literal("Moon_01"),
  16000031: z.literal("Moon_02"),
  16000032: z.literal("Moon_03"),
  16000033: z.literal("Pine_01"),
  16000034: z.literal("Pine_02"),
  16000035: z.literal("Pine_03"),
  16000036: z.literal("Traditional_Star_01"),
  16000037: z.literal("Traditional_Star_02"),
  16000038: z.literal("Traditional_Star_03"),
  16000039: z.literal("Traditional_Star_04"),
  16000040: z.literal("Traditional_Star_05"),
  16000041: z.literal("Traditional_Star_06"),
  16000042: z.literal("Star_Shine_01"),
  16000043: z.literal("Star_Shine_02"),
  16000044: z.literal("Star_Shine_03"),
  16000045: z.literal("Diamond_01"),
  16000046: z.literal("Diamond_02"),
  16000047: z.literal("Diamond_03"),
  16000048: z.literal("flag_a_01"),
  16000049: z.literal("flag_a_02"),
  16000050: z.literal("flag_a_03"),
  16000051: z.literal("flag_b_01"),
  16000052: z.literal("flag_b_02"),
  16000053: z.literal("flag_b_03"),
  16000054: z.literal("flag_c_03"),
  16000055: z.literal("flag_c_04"),
  16000056: z.literal("flag_c_05"),
  16000057: z.literal("flag_c_06"),
  16000058: z.literal("flag_c_07"),
  16000059: z.literal("flag_c_08"),
  16000060: z.literal("flag_d_01"),
  16000061: z.literal("flag_d_02"),
  16000062: z.literal("flag_d_03"),
  16000063: z.literal("flag_d_04"),
  16000064: z.literal("flag_d_05"),
  16000065: z.literal("flag_d_06"),
  16000066: z.literal("flag_f_01"),
  16000067: z.literal("flag_f_02"),
  16000068: z.literal("flag_g_01"),
  16000069: z.literal("flag_g_02"),
  16000070: z.literal("flag_i_01"),
  16000071: z.literal("flag_i_02"),
  16000072: z.literal("flag_h_01"),
  16000073: z.literal("flag_h_02"),
  16000074: z.literal("flag_h_03"),
  16000075: z.literal("flag_j_01"),
  16000076: z.literal("flag_j_02"),
  16000077: z.literal("flag_j_03"),
  16000078: z.literal("flag_k_01"),
  16000079: z.literal("flag_k_02"),
  16000080: z.literal("flag_k_03"),
  16000081: z.literal("flag_k_04"),
  16000082: z.literal("flag_k_05"),
  16000083: z.literal("flag_k_06"),
  16000084: z.literal("flag_l_01"),
  16000085: z.literal("flag_l_02"),
  16000086: z.literal("flag_l_03"),
  16000087: z.literal("flag_m_01"),
  16000088: z.literal("flag_m_02"),
  16000089: z.literal("flag_m_03"),
  16000090: z.literal("flag_n_01"),
  16000091: z.literal("flag_n_02"),
  16000092: z.literal("flag_n_03"),
  16000093: z.literal("flag_n_04"),
  16000094: z.literal("flag_n_05"),
  16000095: z.literal("flag_n_06"),
  16000096: z.literal("Twin_Peaks_01"),
  16000097: z.literal("Twin_Peaks_02"),
  16000098: z.literal("Gem_01"),
  16000099: z.literal("Gem_02"),
  16000100: z.literal("Gem_03"),
  16000101: z.literal("Gem_04"),
  16000102: z.literal("Coin_01"),
  16000103: z.literal("Coin_02"),
  16000104: z.literal("Coin_03"),
  16000105: z.literal("Coin_04"),
  16000106: z.literal("Elixir_01"),
  16000107: z.literal("Elixir_02"),
  16000108: z.literal("Heart_01"),
  16000109: z.literal("Heart_02"),
  16000110: z.literal("Heart_04"),
  16000111: z.literal("Heart_03"),
  16000112: z.literal("Tower_01"),
  16000113: z.literal("Tower_02"),
  16000114: z.literal("Tower_03"),
  16000115: z.literal("Tower_04"),
  16000116: z.literal("Fan_01"),
  16000117: z.literal("Fan_02"),
  16000118: z.literal("Fan_03"),
  16000119: z.literal("Fan_04"),
  16000120: z.literal("Fugi_01"),
  16000121: z.literal("Fugi_02"),
  16000122: z.literal("Fugi_03"),
  16000123: z.literal("Fugi_04"),
  16000124: z.literal("YingYang_01"),
  16000125: z.literal("YingYang_02"),
  16000126: z.literal("flag_c_01"),
  16000127: z.literal("flag_c_02"),
  16000128: z.literal("Cherry_Blossom_01"),
  16000129: z.literal("Cherry_Blossom_02"),
  16000130: z.literal("Cherry_Blossom_03"),
  16000131: z.literal("Cherry_Blossom_04"),
  16000132: z.literal("Cherry_Blossom_06"),
  16000133: z.literal("Cherry_Blossom_05"),
  16000134: z.literal("Cherry_Blossom_07"),
  16000135: z.literal("Cherry_Blossom_08"),
  16000136: z.literal("Bamboo_01"),
  16000137: z.literal("Bamboo_02"),
  16000138: z.literal("Bamboo_03"),
  16000139: z.literal("Bamboo_04"),
  16000140: z.literal("Orange_01"),
  16000141: z.literal("Orange_02"),
  16000142: z.literal("Lotus_01"),
  16000143: z.literal("Lotus_02"),
  16000144: z.literal("A_Char_King_01"),
  16000145: z.literal("A_Char_King_02"),
  16000146: z.literal("A_Char_King_03"),
  16000147: z.literal("A_Char_King_04"),
  16000148: z.literal("A_Char_Barbarian_01"),
  16000149: z.literal("A_Char_Barbarian_02"),
  16000150: z.literal("A_Char_Prince_01"),
  16000151: z.literal("A_Char_Prince_02"),
  16000152: z.literal("A_Char_Knight_01"),
  16000153: z.literal("A_Char_Knight_02"),
  16000154: z.literal("A_Char_Goblin_01"),
  16000155: z.literal("A_Char_Goblin_02"),
  16000156: z.literal("A_Char_DarkPrince_01"),
  16000157: z.literal("A_Char_DarkPrince_02"),
  16000158: z.literal("A_Char_DarkPrince_03"),
  16000159: z.literal("A_Char_DarkPrince_04"),
  16000160: z.literal("A_Char_MiniPekka_01"),
  16000161: z.literal("A_Char_MiniPekka_02"),
  16000162: z.literal("A_Char_Pekka_01"),
  16000163: z.literal("A_Char_Pekka_02"),
  16000164: z.literal("A_Char_Hammer_01"),
  16000165: z.literal("A_Char_Hammer_02"),
  16000166: z.literal("A_Char_Rocket_01"),
  16000167: z.literal("A_Char_Rocket_02"),
  16000168: z.literal("Freeze_01"),
  16000169: z.literal("Freeze_02"),
  16000170: z.literal("Clover_01"),
  16000171: z.literal("Clover_02"),
  16000172: z.literal("flag_h_04"),
  16000173: z.literal("flag_e_02"),
  16000174: z.literal("flag_i_03"),
  16000175: z.literal("flag_e_01"),
  16000176: z.literal("A_Char_Barbarian_03"),
  16000177: z.literal("A_Char_Prince_03"),
  16000178: z.literal("A_Char_Bomb_01"),
  16000179: z.literal("A_Char_Bomb_02"),
});

export type Alliances = z.infer<typeof AlliancesModel>;
