import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  Cards,
  Seasons,
  Locations,
  Latest,
  Arenas,
  GameModes,
  MatchTypes,
  TeamSizes,
} from "@/models/static.ts";
import type { Alliances } from "@/models/alliances.ts";

const authSlice = createSlice({
  name: "static",
  initialState: {
    latest: undefined as Latest | undefined,
    isCardsFetching: false as boolean,
    cards: null as null | Record<string, Cards["items"][0]>,
    isEventsFetching: false as boolean,
    isLocationsFetching: false as boolean,
    locations: null as null | Locations,
    isSeasonsFetching: false as boolean,
    seasons: null as null | Seasons,
    alliances: {} as Alliances,
    arenas: null as null | Arenas,
    gameModes: null as null | GameModes,
    matchTypes: null as null | MatchTypes,
    teamSizes: null as null | TeamSizes,
  },
  reducers: {
    setLatest(state, action: PayloadAction<Latest>) {
      state.latest = action.payload;
    },
    setCards(state, action: PayloadAction<Record<string, Cards["items"][0]>>) {
      state.cards = action.payload;
    },
    setLocations(state, action: PayloadAction<Locations>) {
      state.locations = action.payload;
    },
    setSeasons(state, action: PayloadAction<Seasons>) {
      state.seasons = action.payload;
    },
    setAlliances(state, action: PayloadAction<Alliances>) {
      state.alliances = action.payload;
    },
    setArenas(state, action: PayloadAction<Arenas>) {
      state.arenas = action.payload;
    },
    setGameModes(state, action: PayloadAction<GameModes>) {
      state.gameModes = action.payload;
    },
    setMatchTypes(state, action: PayloadAction<MatchTypes>) {
      state.matchTypes = action.payload;
    },
    setTeamSizes(state, action: PayloadAction<TeamSizes>) {
      state.teamSizes = action.payload;
    },
  },
});

export const {
  setCards,
  setSeasons,
  setLocations,
  setLatest,
  setAlliances,
  setArenas,
  setGameModes,
  setMatchTypes,
  setTeamSizes,
} = authSlice.actions;
export default authSlice.reducer;
