import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInAnonymously,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  getRedirectResult,
  onAuthStateChanged,
  signOut,
  signInWithRedirect,
} from "firebase/auth";
import type { User, UserCredential } from "firebase/auth";
import { refs } from "@/utils/refs.ts";
import { EVENTS } from "@/utils/events.ts";

// Strict IP address whitelisting is enabled for the domain
const app = initializeApp({
  apiKey: "AIzaSyCDTFn8x_aOZJyx7hv98t3IO62WmOAWphU",
  authDomain: "royaletactics.com",
});

const provider = new GoogleAuthProvider();

const auth = getAuth(app);
if (refs.analytics) refs.analytics = getAnalytics(app);
auth.useDeviceLanguage();

export async function createUser(
  email: string,
  password: string
): Promise<UserCredential> {
  const result = await createUserWithEmailAndPassword(auth, email, password);
  refs.pom(EVENTS.USER_CREATE, { email, guid: result.user.uid });
  return result;
}

export async function authenticateCred(email: string, password: string) {
  const result = await signInWithEmailAndPassword(auth, email, password);
  refs.pom(EVENTS.USER_LOGIN, { email, guid: result.user.uid });
}

export async function authenticateGoogle(): Promise<UserCredential> {
  await signInWithRedirect(auth, provider);
  const result = await getRedirectResult(auth);
  if (!result) throw new Error("No redirect result");
  refs.pom(EVENTS.GOOGLE_AUTH, {
    email: result.user.email ?? "",
    guid: result.user.uid,
  });
  return result;
}

export async function authAnonymously() {
  await signInAnonymously(auth);
  refs.pom(EVENTS.USER_LOGIN_ANONYMOUS);
}

export async function authSignOut() {
  try {
    await signOut(auth);
    const payload =
      auth.currentUser && !auth.currentUser.isAnonymous
        ? {
            email: auth.currentUser.email ?? "",
            guid: auth.currentUser.uid,
          }
        : undefined;
    refs.pom(EVENTS.USER_LOGOUT, payload);
  } catch (e) {
    console.error("Failed to sign out", e);
  }
}

export async function authPasswordResetEmail(email: string) {
  await sendPasswordResetEmail(auth, email);
  refs.pom(EVENTS.USER_RESET_PASSWORD, { email });
}

export function watchAuthChanges(
  callback: (arg: User | null) => void,
  onComplete: () => void
) {
  return onAuthStateChanged(auth, callback, undefined, onComplete);
}
