"use client";
import "@/utils/prototypes.ts";
import { useEffect } from "react";
import { refs } from "@/utils/refs.ts";
import { watchAuthChanges } from "@/utils/auth.ts";
import { useAppDispatch } from "@/utils/hooks.ts";
import { setUser, setAccount, setProfiles } from "@/utils/reducer-auth.ts";
import type { Cards } from "@/models/static.ts";
import {
  setCards,
  setSeasons,
  setLocations,
  setLatest,
  setAlliances,
  setArenas,
  setGameModes,
  setMatchTypes,
  setTeamSizes,
} from "@/utils/reducer-static.ts";
import * as Sentry from "@sentry/react";
import { IS_PROD } from "@/constants-app.ts";
import getVersion from "@/fetches/version.ts";
import { createOrGetAccount } from "@/fetches/account.ts";
import { getProfiles } from "@/fetches/profile.ts";
import {
  fetchLatest,
  fetchCards,
  fetchLocations,
  fetchSeasons,
  fetchArenas,
  fetchGameModes,
  fetchMatchTypes,
  fetchTeamSizes,
} from "@/fetches/static.ts";
import { fetchAlliances } from "@/fetches/alliances.ts";
import useTopClans from "@/app/topclans/use-top-clans.ts";
import { EVENTS } from "@/utils/events.ts";
import { usePathname } from "next/navigation";

export default function Init() {
  const pathname = usePathname();
  const dispatch = useAppDispatch();
  useTopClans();
  useEffect(() => {
    refs.pom(EVENTS.PAGE_VIEW);
  }, [pathname]);
  useEffect(() => {
    (async function init() {
      await Promise.all([
        // Fetch the latest hash ids, fetch all static data based on the hash ids
        (async () => {
          const latest = await fetchLatest();
          if (!latest) return;
          dispatch(setLatest(latest));
          // Fetch seasons, locations and cards
          await Promise.all([
            (async () => {
              const seasons = await fetchSeasons(latest.seasons);
              if (!seasons) return;
              dispatch(setSeasons(seasons));
            })(),
            (async () => {
              const locations = await fetchLocations(latest.locations);
              if (!locations) return;
              dispatch(setLocations(locations));
            })(),
            (async () => {
              const cards = await fetchCards(latest.cards);
              if (!cards) return;
              // Convert array of items + supportItems into a hash using the card id as the key
              const cardsHash = Object.create(null);
              for (const i of [...cards.items, ...cards.supportItems]) {
                if (cardsHash[i.id]) continue;
                cardsHash[i.id] = i;
              }
              dispatch(
                setCards(cardsHash as Record<string, Cards["items"][0]>)
              );
            })(),
            (async () => {
              const payload = await fetchArenas();
              if (!payload) return;
              dispatch(
                setArenas(
                  payload.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  })
                )
              );
            })(),
            (async () => {
              const payload = await fetchGameModes();
              if (!payload) return;
              dispatch(
                setGameModes(
                  payload.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  })
                )
              );
            })(),
            (async () => {
              const payload = await fetchMatchTypes();
              if (!payload) return;
              dispatch(setMatchTypes(payload.sort()));
            })(),
            (async () => {
              const payload = await fetchTeamSizes();
              if (!payload) return;
              dispatch(setTeamSizes(payload));
            })(),
          ]);
        })(),
        // Alliances / Clans
        (async () => {
          const alliances = await fetchAlliances();
          if (!alliances) return;
          dispatch(setAlliances(alliances));
        })(),
        // Fetch Royale Tactics version
        (async () => {
          const version = await fetchAndAssignVersion();
          if (!refs.sentry && IS_PROD)
            refs.sentry = Sentry.init({
              release: `royaletactics@${version}`,
              dsn: "https://f5ac07d630737d80ff1d1c5732f28811@o4507618341093376.ingest.us.sentry.io/4507618362064896",
              integrations: [
                Sentry.browserTracingIntegration(),
                // 8% (117KiB) extra resource bytes for profile page
                // Sentry.replayIntegration(),
              ],
              environment: IS_PROD ? "production" : "development",
              // Performance Monitoring
              tracesSampleRate: 1.0, //  Capture 100% of the transactions
              // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
              tracePropagationTargets: IS_PROD
                ? [/^https:\/\/royaletactics\.com/]
                : ["localhost"],
              // Session Replay
              replaysSessionSampleRate: IS_PROD ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
              replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
        })(),
      ]);
    })();
    // Setup local authentication handlers
    if (!refs.authUnsubscribe)
      // Watch for auth changes, eg. sign in, sign out, account changes, etc.
      refs.authUnsubscribe = watchAuthChanges(
        (user) => {
          const _user = {
            displayName: "",
            email: "",
          };
          if (user) {
            if (user.isAnonymous) {
              _user.displayName = "Guest User";
            } else {
              if (user.displayName) _user.displayName = user.displayName;
              if (user.email) _user.email = user.email;
              (async () => {
                const account = await createOrGetAccount(user.uid);
                if (!account) return;
                dispatch(setAccount(account));
                const profiles = await getProfiles(user.uid);
                if (!profiles) return;
                dispatch(setProfiles({ [user.uid]: profiles }));
              })();
            }
          } else {
            _user.displayName = "Account";
          }
          dispatch(setUser(user ? _user : null));
        },
        () => {
          console.warn("Auth: onComplete callback");
        }
      );
    // Teardown
    return () => {
      // Sentry
      refs.sentry?.close();
      refs.sentry = undefined;
      // Auth
      refs.authUnsubscribe?.(); // Unsubscribe
      refs.authUnsubscribe = null;
    };
  }, []);
  return null;
}

async function fetchAndAssignVersion(): Promise<string> {
  const versioning = await getVersion();
  if (!versioning?.version) return refs.version;
  refs.version = versioning.version;
  console.log(`RoyaleTactics ${refs.version}`);
  return refs.version;
}
