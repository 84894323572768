import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { Player } from "@/models/player.ts";
import type { Clan } from "@/models/clan.ts";
import _ from "lodash";

const profilesSlice = createSlice({
  name: "game",
  initialState: {
    profile: {} as Record<string, Player>, // :playerTag
    clan: {} as Record<string, Clan>, // :clanTag
    guid: {} as Record<string, string>, // :playerTag, :guid
  },
  reducers: {
    setProfile(state, action: PayloadAction<Player | undefined>): void {
      const tag = action.payload?.player.tag;
      if (
        !tag ||
        (state.profile[tag] && state.profile[tag] === action.payload) ||
        (state.profile[tag] && _.isEqual(state.profile[tag], action.payload))
      )
        return;
      state.profile[tag] = action.payload as Player;
    },
    setClan(state, action: PayloadAction<Clan | undefined>): void {
      const tag = action.payload?.clan.tag;
      if (
        !tag ||
        (state.profile[tag] && state.clan[tag] === action.payload) ||
        (state.profile[tag] && _.isEqual(state.clan[tag], action.payload))
      )
        return;
      state.clan[tag] = action.payload as Clan;
    },
    setGUID(
      state,
      action: PayloadAction<{ playerTag: string; guid: string }>
    ): void {
      if (state.guid[action.payload.playerTag] === action.payload.guid) return;
      state.guid[action.payload.playerTag] = action.payload.guid;
    },
  },
});

export const { setProfile, setClan, setGUID } = profilesSlice.actions;
export default profilesSlice.reducer;
