import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  UnpackMap,
  UnpackCharacters,
  UnpackProjectiles,
  UnpackBuildings,
  UnpackSpellsCharacters,
  UnpackSpellsOther,
  UnpackAOE,
} from "@/models/unpack.ts";

const unpackSlice = createSlice({
  name: "unpack",
  initialState: {
    map: {} as UnpackMap,
    characters: {} as Record<string, UnpackCharacters[0]>,
    projectiles: {} as Record<string, UnpackProjectiles[0]>,
    buildings: {} as Record<string, UnpackBuildings[0]>,
    spellsCharacters: {} as Record<string, UnpackSpellsCharacters[0]>,
    spellsCharactersEvolved: {} as Record<string, UnpackSpellsCharacters[0]>,
    spellsOther: {} as Record<string, UnpackSpellsOther[0]>,
    spellsOtherEvolved: {} as Record<string, UnpackSpellsOther[0]>,
    aoe: {} as Record<string, UnpackAOE[0]>,
  },
  reducers: {
    setUnpackMap(state, action: PayloadAction<UnpackMap>): void {
      state.map = action.payload;
    },
    setUnpackProjectiles(
      state,
      action: PayloadAction<UnpackProjectiles>
    ): void {
      state.projectiles = action.payload.reduce((acc, cur) => {
        acc[cur.Name] = cur;
        return acc;
      }, {} as Record<string, UnpackProjectiles[0]>);
    },
    setUnpackCharacters(state, action: PayloadAction<UnpackCharacters>): void {
      state.characters = action.payload.reduce((acc, cur) => {
        acc[cur.Name] = cur;
        return acc;
      }, {} as Record<string, UnpackCharacters[0]>);
    },
    setUnpackBuildings(state, action: PayloadAction<UnpackBuildings>): void {
      state.buildings = action.payload.reduce((acc, cur) => {
        acc[cur.Name] = cur;
        return acc;
      }, {} as Record<string, UnpackBuildings[0]>);
    },
    setUnpackSpellsCharacters(
      state,
      action: PayloadAction<UnpackSpellsCharacters>
    ): void {
      state.spellsCharacters = action.payload.reduce((acc, cur) => {
        acc[cur.Name] = cur;
        return acc;
      }, {} as Record<string, UnpackSpellsCharacters[0]>);
    },
    setUnpackSpellsCharactersEvolved(
      state,
      action: PayloadAction<UnpackSpellsCharacters>
    ): void {
      state.spellsCharactersEvolved = action.payload.reduce((acc, cur) => {
        acc[cur.Name] = cur;
        return acc;
      }, {} as Record<string, UnpackSpellsCharacters[0]>);
    },
    setUnpackSpellsOther(
      state,
      action: PayloadAction<UnpackSpellsOther>
    ): void {
      state.spellsOther = action.payload.reduce((acc, cur) => {
        acc[cur.Name] = cur;
        return acc;
      }, {} as Record<string, UnpackSpellsOther[0]>);
    },
    setUnpackSpellsOtherEvolved(
      state,
      action: PayloadAction<UnpackSpellsOther>
    ): void {
      state.spellsOtherEvolved = action.payload.reduce((acc, cur) => {
        acc[cur.Name] = cur;
        return acc;
      }, {} as Record<string, UnpackSpellsOther[0]>);
    },
    setUnpackAOE(state, action: PayloadAction<UnpackAOE>): void {
      state.aoe = action.payload.reduce((acc, cur) => {
        acc[cur.Name] = cur;
        return acc;
      }, {} as Record<string, UnpackAOE[0]>);
    },
  },
});

export const {
  setUnpackMap,
  setUnpackProjectiles,
  setUnpackCharacters,
  setUnpackBuildings,
  setUnpackSpellsCharacters,
  setUnpackSpellsCharactersEvolved,
  setUnpackSpellsOther,
  setUnpackSpellsOtherEvolved,
  setUnpackAOE,
} = unpackSlice.actions;
export default unpackSlice.reducer;
