import { z } from "zod";

export const AccountModel = z.object({
  guid: z.string(),
  createdAt: z.number(),
  lastUpdated: z.number(),
  profiles: z.record(
    z.string(),
    z.object({
      expiry: z.number(),
      createdAt: z.number(),
      cardVerification: z
        .array(z.object({ index: z.number(), cardId: z.number() }))
        .min(0)
        .max(4)
        .transform((arr) => arr.sort((a, b) => a.index - b.index)),
    })
  ),
  notification: z.record(z.string(), z.boolean()).optional(),
});

export type Account = z.infer<typeof AccountModel>;
