import type { Version } from "@/models/version.ts";
import { VersionModel } from "@/models/version.ts";
import * as Sentry from "@sentry/react";

export default async function getVersion(): Promise<Version | undefined> {
  try {
    const response = await fetch("/api/version", {
      method: "GET",
    });
    if (!response.ok) throw new Error("Failed to fetch version data");
    const data = await response.json();
    const parsed = VersionModel.parse(data);
    return parsed;
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
}
