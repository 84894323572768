import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    location: "" as string,
  },
  reducers: {
    setLocation(state, action: PayloadAction<string>): void {
      state.location = action.payload;
    },
  },
});

export const { setLocation } = settingsSlice.actions;
export default settingsSlice.reducer;
