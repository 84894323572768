import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { IS_PROD } from "@/constants-app";
import commonEN from "@/locales/en/common.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: !IS_PROD,
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    ns: ["common"], // Specify namespaces
    defaultNS: "common", // Set default namespace
    supportedLngs: [
      "en",
      "en-US",
      //  "pt-PT", "pt-BR", "zh-CN", "zh-TW"
    ],
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["localStorage", "cookie"],
    },
    load: "currentOnly",
    resources: {
      en: { common: commonEN },
      ["en-US"]: { common: commonEN },
    },
  });

export default i18n;
