import { API } from "@/constants-app";
import { ProfilesModel } from "@/models/profile.ts";
import type { Profiles } from "@/models/profile.ts";

type VerifyCardIds = [string, string, string, string];

export async function getProfiles(guid: string): Promise<Profiles> {
  const response = await fetch(`${API}/account/user/${guid}/profiles`);
  const json = await response.json();
  const parsed = ProfilesModel.parse(json) as VerifyCardIds;
  return parsed;
}
