"use client";

import { refs } from "@/utils/refs.ts";
import { makeStore } from "@/utils/store.ts";
import { AppProvider as PolarisProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
// import zhCNTranslations from "@shopify/polaris/locales/zh-CN.json";
// import zhTWTranslations from "@shopify/polaris/locales/zh-TW.json";
// import ptBRTranslations from "@shopify/polaris/locales/pt-BR.json";
// import ptPTTranslations from "@shopify/polaris/locales/pt-PT.json";
import { Provider as ReduxProvider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "@/app/i18n.ts";
import { SWRConfig } from "swr";

export default function Providers({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <PolarisProvider
      i18n={[
        enTranslations, // English
        // zhCNTranslations, // Simplified Chinese
        // zhTWTranslations, // Traditional Chinese
        // ptBRTranslations, // Brazilian Portuguese
        // ptPTTranslations, // Euro Portuguese
      ]}
    >
      <ReduxProvider store={(refs.reduxStore ||= makeStore())}>
        <I18nextProvider i18n={i18n}>
          <SWRConfig
            value={{
              shouldRetryOnError: false,
              revalidateIfStale: true,
              dedupingInterval: 15e3,
            }}
          >
            {children}
          </SWRConfig>
        </I18nextProvider>
      </ReduxProvider>
    </PolarisProvider>
  );
}
