import { configureStore } from "@reduxjs/toolkit";
import authReducer from "@/utils/reducer-auth.ts";
import searchReducer from "@/utils/reducer-search.ts";
import staticReducer from "@/utils/reducer-static.ts";
import settingsReducer from "@/utils/reducer-settings.ts";
import gameReducer from "@/utils/reducer-game.ts";
import unpackReducer from "@/utils/reducer-unpack.ts";

export const makeStore = () => {
  return configureStore({
    reducer: {
      auth: authReducer,
      search: searchReducer,
      static: staticReducer,
      settings: settingsReducer,
      game: gameReducer,
      unpack: unpackReducer,
    },
  });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
