import {
  API,
  FETCH_REVALIDATE_HOUR,
  FETCH_REVALIDATE_MAX,
} from "@/constants-app.ts";
import { EventsModel, type Events } from "@/models/events.ts";
import type {
  Latest,
  Cards,
  Locations,
  Seasons,
  Arenas,
  GameModes,
  MatchTypes,
  TeamSizes,
} from "@/models/static.ts";
import {
  LatestModel,
  CardsModel,
  LocationsModel,
  SeasonsModel,
  ArenasModel,
  MatchTypesModel,
} from "@/models/static.ts";
import * as Sentry from "@sentry/react";

export async function fetchLatest(): Promise<Latest | undefined> {
  try {
    const response = await fetch(`${API}/latest/static`, {
      method: "GET",
    });
    if (!response.ok) throw new Error("Failed to fetch player data");
    const data = await response.json();
    const parsed = LatestModel.parse(data);
    return parsed;
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
}

export async function fetchSeasons(hash: string): Promise<Seasons | undefined> {
  try {
    if (!hash) throw new Error("No hash arg for seasons");
    const response = await fetch(`${API}/seasons/${hash}`, {
      method: "GET",
      headers: {
        ["Referer"]: "https://royaletactics.com",
      },
      cache: "force-cache",
      next: { revalidate: FETCH_REVALIDATE_MAX },
    });
    if (!response.ok) throw new Error("Failed to fetch seasons data");
    const data = await response.json();
    const parsed = SeasonsModel.parse(data);
    return parsed;
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
}

export async function fetchCards(hash: string): Promise<Cards | undefined> {
  try {
    if (!hash) throw new Error("No hash arg for cards");
    const response = await fetch(`${API}/cards/${hash}`, {
      method: "GET",
      headers: {
        ["Referer"]: "https://royaletactics.com",
      },
      cache: "force-cache",
      next: { revalidate: FETCH_REVALIDATE_MAX },
    });
    if (!response.ok) throw new Error("Failed to fetch cards data");
    const data = await response.json();
    const parsed = CardsModel.parse(data);
    return parsed;
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
}

export async function fetchLocations(
  hash: string
): Promise<Locations | undefined> {
  try {
    if (!hash) throw new Error("No hash arg for locations");
    const response = await fetch(`${API}/locations/${hash}`, {
      method: "GET",
      headers: {
        ["Referer"]: "https://royaletactics.com",
      },
      cache: "force-cache",
      next: { revalidate: FETCH_REVALIDATE_MAX },
    });
    if (!response.ok) throw new Error("Failed to fetch location data");
    const data = await response.json();
    const parsed = LocationsModel.parse(data);
    return parsed;
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
}

export async function fetchEvents(hash?: string): Promise<Events | undefined> {
  try {
    if (!hash) {
      console.error("Failed to fetch events data with no hash");
      return;
    }
    const response = await fetch(`${API}/events/${hash}`, {
      method: "GET",
      headers: {
        ["Referer"]: "https://royaletactics.com",
      },
      cache: "force-cache",
      next: { revalidate: FETCH_REVALIDATE_HOUR },
    });
    if (!response.ok) throw new Error("Failed to fetch events data");
    const data = await response.json();
    const parsed = EventsModel.parse(data);
    return parsed;
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
}

export async function fetchArenas(): Promise<Arenas | undefined> {
  try {
    const response = await fetch(`${API}/category/arenas`, {
      method: "GET",
      headers: {
        ["Referer"]: "https://royaletactics.com",
      },
    });
    if (!response.ok) throw new Error("Failed to fetch arenas data");
    const data = await response.json();
    const parsed = ArenasModel.parse(data);
    return parsed;
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
}

export async function fetchGameModes(): Promise<GameModes | undefined> {
  try {
    const response = await fetch(`${API}/category/gamemodes`, {
      method: "GET",
      headers: {
        ["Referer"]: "https://royaletactics.com",
      },
    });
    if (!response.ok) throw new Error("Failed to fetch game modes data");
    const data = await response.json();
    const parsed = ArenasModel.parse(data);
    return parsed;
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
}

export async function fetchMatchTypes(): Promise<MatchTypes | undefined> {
  try {
    const response = await fetch(`${API}/category/matchtypes`, {
      method: "GET",
      headers: {
        ["Referer"]: "https://royaletactics.com",
      },
    });
    if (!response.ok) throw new Error("Failed to fetch match types data");
    const data = await response.json();
    const parsed = MatchTypesModel.parse(data);
    return parsed;
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
}

export async function fetchTeamSizes(): Promise<TeamSizes | undefined> {
  try {
    const response = await fetch(`${API}/category/teamsizes`, {
      method: "GET",
      headers: {
        ["Referer"]: "https://royaletactics.com",
      },
    });
    if (!response.ok) throw new Error("Failed to fetch team sizes data");
    const data = await response.json();
    const parsed = MatchTypesModel.parse(data);
    return parsed;
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
}
