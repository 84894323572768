import { STATIC_URL } from "@/constants-game.ts";
import type { Alliances } from "@/models/alliances.ts";
import { AlliancesModel } from "@/models/alliances.ts";
import { captureException } from "@sentry/react";

export async function fetchAlliances(): Promise<Alliances | undefined> {
  try {
    const response = await fetch(`${STATIC_URL}/alliance/alliances.json`);
    if (!response.ok) throw new Error("Failed to create user account data");
    const data = await response.json();
    const parsed = AlliancesModel.parse(data);
    return parsed;
  } catch (e) {
    console.error(e);
    captureException(e);
  }
}
