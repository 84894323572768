import Link from "next/link";
import { Page } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { usePathname } from "next/navigation";
import { NAVIGATION } from "@/constants-app.ts";

export default function MainNavigationTablet() {
  const { t } = useTranslation();
  const pathname = usePathname();
  return (
    <div
      style={{
        backgroundColor: "var(--p-color-bg-inverse)",
      }}
      className="main-nav-tablet"
    >
      <Page>
        <div className="flex items-center justify-center px-2 text-neutral-400 font-medium flex gap-5">
          {NAVIGATION.map((i) => {
            const isCurrentPage = pathname.startsWith(i.url);
            return (
              <Link
                href={i.url}
                key={i.url}
                className={`relative pb-2 ${isCurrentPage ? "text-white" : ""}`}
              >
                {t(...i.title)}
                {isCurrentPage && (
                  <div
                    style={{ height: "2px", background: "var(--rt-color)" }}
                    className="absolute bottom-0 left-0 w-full rounded-tl-lg rounded-tr-lg"
                  />
                )}
              </Link>
            );
          })}
        </div>
      </Page>
    </div>
  );
}
