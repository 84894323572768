import { API } from "@/constants-app";
import { AccountModel } from "@/models/account.ts";
import type { Account } from "@/models/account.ts";
import { captureException } from "@sentry/react";

export async function createOrGetAccount(
  guid: string // Google UID
): Promise<Account | undefined> {
  try {
    const response = await fetch(`${API}/account/user/${guid}`, {
      method: "POST",
    });
    if (!response.ok) throw new Error("Failed to create user account data");
    const data = await response.json();
    const parsed = AccountModel.parse(data);
    return parsed;
  } catch (e) {
    console.error(e);
    captureException(e);
  }
}

export async function addProfile(
  guid: string,
  tag: string
): Promise<Account | undefined> {
  try {
    const response = await fetch(`${API}/account/user/${guid}/profile`, {
      method: "POST",
      body: JSON.stringify({
        tag,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) throw new Error("Failed to create user account data");
    const data = await response.json();
    const parsed = AccountModel.parse(data);
    return parsed;
  } catch (e) {
    console.error(e);
    captureException(e);
  }
}

export async function verifyProfile(
  guid: string,
  tag: string
): Promise<{ expiry: number } | undefined> {
  try {
    const response = await fetch(`${API}/account/user/${guid}/profile/verify`, {
      method: "POST",
      body: JSON.stringify({
        tag,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    // 0 means success, any positive integer indicates that we're blocking verification due to a failed verification
    return data as { expiry: number };
  } catch (e) {
    console.error(e);
    captureException(e);
  }
}
