import { z } from "zod";

export const LatestModel = z.object({
  seasons: z.string(),
  cards: z.string(),
  locations: z.string(),
  events: z.string(),
  arenas: z.string(),
});

export const SeasonsModel = z.object({
  date: z.number(),
  seasons: z.array(
    z.object({
      code: z.string(),
      endTime: z.number(),
      uniqueId: z.string(),
    })
  ),
});

const IconUrls = z.object({
  medium: z.string().optional(),
  evolutionMedium: z.string().optional(),
});
const Cards = z.object({
  maxLevel: z.number().optional(),
  evolutionLevel: z.number().optional(),
  name: z.string(),
  iconUrls: IconUrls,
  id: z.number(),
  maxEvolutionLevel: z.number().optional(),
  rarity: z.string(),
  elixirCost: z.number().optional(),
});
export const CardsModel = z.object({
  date: z.number(),
  items: z.array(Cards),
  supportItems: z.array(Cards),
});

export const LocationsModel = z.object({
  date: z.number(),
  locations: z.array(
    z.object({
      isCountry: z.boolean(),
      name: z.string(),
      id: z.number(),
      countryCode: z.string().optional(),
    })
  ),
});

export const ArenasModel = z.array(
  z.object({ id: z.number(), name: z.string() })
);
export const MatchTypesModel = z.array(z.string());

export type Latest = z.infer<typeof LatestModel>;
export type Seasons = z.infer<typeof SeasonsModel>;
export type Cards = z.infer<typeof CardsModel>;
export type Locations = z.infer<typeof LocationsModel>;
export type Arenas = z.infer<typeof ArenasModel>;
export type GameModes = z.infer<typeof ArenasModel>;
export type MatchTypes = z.infer<typeof MatchTypesModel>;
export type TeamSizes = z.infer<typeof MatchTypesModel>;
