"use client";

import {
  TopBar as TopBarPolaris,
  Icon,
  Frame,
  Text,
  Navigation as NavigationPolaris,
  useBreakpoints,
} from "@shopify/polaris";
import {
  QuestionCircleIcon,
  ArrowLeftIcon,
  HomeIcon,
  PersonIcon,
  LogoMetaIcon,
  LogoXIcon,
  LogoTiktokIcon,
  LogoYoutubeIcon,
  LogoVimeoIcon,
  ChatIcon,
  LogoInstagramIcon,
} from "@shopify/polaris-icons";
import { useState, useCallback, useMemo } from "react";
import { useAppSelector } from "@/utils/hooks.ts";
import { LOGO_URL } from "@/constants-game.ts";
import Search from "@/components/Search.tsx";
import { authSignOut } from "@/utils/auth.ts";
import { useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";
import MainNavigationTablet from "@/app/MainNavigationTablet.tsx";
import { NAVIGATION } from "@/constants-app.ts";

export default function TopBar({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const router = useRouter();
  const { mdDown } = useBreakpoints();
  const user = useAppSelector((state) => state.auth.user);
  const [isNavigation, setIsNavigation] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isSecondaryMenuOpen, setIsSecondaryMenuOpen] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const handleNavigationToggle = useCallback(() => {
    setIsNavigation((prev) => !prev);
  }, []);
  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    []
  );
  const toggleIsSecondaryMenuOpen = useCallback(
    () => setIsSecondaryMenuOpen((isSecondaryMenuOpen) => !isSecondaryMenuOpen),
    []
  );
  const handleSearchResultsDismiss = useCallback(() => {
    setIsSearchActive(false);
  }, []);
  const handleSignOut = useCallback(async () => {
    await authSignOut();
    router.push("/");
  }, []);
  const handleRedirect = useCallback(
    (path: "account" | "settings") => () => {
      if (user) {
        router.push(`/${path}`);
        return;
      }
      router.push("/login");
    },
    [user]
  );
  const userMenu = useMemo(() => {
    if (user) {
      return [
        {
          items: [
            {
              content: "Log out",
              icon: ArrowLeftIcon,
              onAction: handleSignOut,
            },
            { content: "Account", onAction: handleRedirect("account") },
            { content: "Settings", onAction: handleRedirect("settings") },
          ],
        },
        {
          items: [{ content: "My Profiles" }, { content: "My Clans" }],
        },
      ];
    }
    return [
      {
        items: [
          { content: "Login", url: "/login" },
          { content: "Register", url: "/register" },
        ],
      },
    ];
  }, [user]);
  const secondaryMenu = useMemo(() => {
    const result = [
      {
        items: [
          { content: "Instagram" },
          { content: "Discord" },
          { content: "TikTok" },
        ],
        title: "Socials",
      },
    ];
    if (user)
      result.push({
        items: [{ content: "Bug Report" }, { content: "Contact Support" }],
        title: "Support",
      });
    return result;
  }, [user]);
  const toastMarkup = useMemo(() => undefined, []);
  const modalMarkup = useMemo(() => undefined, []);
  return (
    <>
      <Frame
        topBar={
          <>
            <TopBarPolaris
              showNavigationToggle
              userMenu={
                <TopBarPolaris.UserMenu
                  actions={userMenu}
                  name={user ? user.displayName ?? "No Username" : "Account"}
                  detail={user ? user.email ?? "No Email" : ""}
                  initials={user ? user.displayName?.[0] ?? "G" : "A"}
                  open={isUserMenuOpen}
                  onToggle={toggleIsUserMenuOpen}
                />
              }
              secondaryMenu={
                <TopBarPolaris.Menu
                  activatorContent={
                    <span>
                      <Icon source={QuestionCircleIcon} />
                      <Text as="span" visuallyHidden>
                        Secondary menu
                      </Text>
                    </span>
                  }
                  open={isSecondaryMenuOpen}
                  onOpen={toggleIsSecondaryMenuOpen}
                  onClose={toggleIsSecondaryMenuOpen}
                  actions={secondaryMenu}
                />
              }
              searchResultsVisible={isSearchActive}
              searchField={<Search isDark />}
              onSearchResultsDismiss={handleSearchResultsDismiss}
              onNavigationToggle={handleNavigationToggle}
            />
            <div className="md:block hidden">
              <MainNavigationTablet />
            </div>
          </>
        }
        navigation={
          mdDown ? (
            <Navigation onNavigationDismiss={handleNavigationToggle} />
          ) : undefined
        }
        showMobileNavigation={isNavigation}
        onNavigationDismiss={handleNavigationToggle}
        logo={{
          topBarSource: LOGO_URL,
          contextualSaveBarSource: LOGO_URL,
          url: "/",
          accessibilityLabel: "Royale Tactics",
          width: 80,
        }}
      >
        <div className="md:pt-12 pt-0">
          {children}
          {toastMarkup}
          {modalMarkup}
        </div>
      </Frame>
    </>
  );
}

function Hey() {
  return <div>{LOGO_URL}</div>;
}

function Navigation({
  onNavigationDismiss,
}: {
  onNavigationDismiss: () => void;
}) {
  const { t } = useTranslation();
  const account = useAppSelector((state) => state.auth.account);
  const navigation = useMemo(() => {
    const items = [
      {
        label: "Homepage",
        url: "/",
        onClick: onNavigationDismiss,
      },
      ...NAVIGATION.map((i) => ({
        label: t(...i.title),
        url: i.url,
        onClick: onNavigationDismiss,
      })),
    ];
    return items;
  }, []);
  const navigationAccount = useMemo(() => {
    return [
      {
        label: "Dashboard",
        icon: HomeIcon,
        url: "/dashboard",
        onClick: onNavigationDismiss,
      },
      {
        label: "Profile",
        icon: PersonIcon,
        url: "/account",
        onClick: onNavigationDismiss,
      },
      // {
      //   label: "Clan",
      //   icon: ShieldPersonIcon,
      //   onClick: () => router.push("/dashboard"),
      // }
    ];
  }, [account]);
  return (
    <NavigationPolaris location="/" ariaLabelledBy="Navigation">
      <NavigationPolaris.Section
        separator
        title="Royale Tactics"
        items={navigation}
      />
      {!!account && (
        <NavigationPolaris.Section
          separator
          title="Account"
          items={navigationAccount}
          action={{
            icon: ChatIcon,
            accessibilityLabel: "Bug report",
            onClick: () => {},
          }}
        />
      )}
      <NavigationPolaris.Section
        separator
        title="Socials"
        items={[
          {
            label: "Discord",
            icon: LogoVimeoIcon,
            onClick: () => {},
          },
          {
            label: "YouTube",
            icon: LogoYoutubeIcon,
            onClick: () => {},
          },
          {
            label: "Instagram",
            icon: LogoInstagramIcon,
            onClick: () => {},
          },
          {
            label: "Tik Tok",
            icon: LogoTiktokIcon,
            onClick: () => {},
          },
          {
            label: "X / Twitter",
            icon: LogoXIcon,
            onClick: () => {},
          },
          {
            label: "Meta / Facebook",
            icon: LogoMetaIcon,
            onClick: () => {},
          },
        ]}
      />
    </NavigationPolaris>
  );
}
