import type { Account } from "@/models/account.ts";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { NOTIFICATION } from "@/constants-game.ts";

type User = { displayName: string | null; email: string | null };

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null as User | null,
    account: null as Account | null,
    profiles: {} as Record<string, Array<string>>,
  },
  reducers: {
    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload;
    },
    setAccount(state, action: PayloadAction<Account>) {
      state.account = action.payload;
    },
    setProfiles(state, action: PayloadAction<Record<string, Array<string>>>) {
      const next = {
        ...state.profiles,
      };
      for (const guid in action.payload) {
        next[guid] = action.payload[guid];
      }
      state.profiles = next;
    },
    setNotification(
      state,
      action: PayloadAction<
        Record<(typeof NOTIFICATION)[keyof typeof NOTIFICATION], boolean>
      >
    ) {
      const next = {
        ...state.account,
        notification: Object.assign(
          state.account?.notification ?? {},
          action.payload
        ),
      };
      state.account = next as Account;
    },
  },
});

export const { setUser, setAccount, setProfiles, setNotification } =
  authSlice.actions;
export default authSlice.reducer;
